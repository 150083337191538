import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
// import Header from "../components/Header"
import Footer from "../components/Footer";
import "../css/story.scss";

import StoryRoad from "../images/story/story-road.png";
import StoryApples from "../images/story/story-apples.png";
import StoryHouse from "../images/story/story-house.png";
import StoryFamily from "../images/story/story-family.png";
import StoryFence from "../images/story/story-fence.png";
import StoryTypist from "../images/story/story-typist.png";
import StoryTypewriter from "../images/story/story-typewriter.png";
import StoryLetter from "../images/story/story-letter.png";
import StoryType from "../images/story/story-type.png";
import StoryAz from "../images/story/story-az-motion.svg";
// import StoryAz from "../images/story/story-az.svg"

import Figure1 from "../images/figure1.svg";
import Figure11 from "../images/figure11.svg";
import Figure22 from "../images/figure22.svg";
import Figure3 from "../images/figure3.svg";
import Figure33 from "../images/figure33.svg";
// import Figures from "../images/figures3.svg"

if (typeof window === "undefined") {
  global.window = {};
}

if (typeof window !== "undefined") {
  // console.log("--defined")
  gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);
}

// gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, MotionPathPlugin)

const Story = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const FIG1 = document.getElementById("FIG1");
    const FIG11 = document.getElementById("FIG11");
    // const FIG22 = document.getElementById("FIG22")
    const FIG3 = document.getElementById("FIG3");
    const FIG33 = document.getElementById("FIG33");

    let FIG1Y = 790;
    let FIG3Y = 760;
    if (
      document.documentElement.clientWidth > 767 &&
      document.documentElement.clientWidth < 1024
    ) {
      // console.log("--->767")
      FIG1Y = 750;
      FIG3Y = 810;
    } else if (
      document.documentElement.clientWidth > 1023 &&
      document.documentElement.clientWidth < 1280
    ) {
      // console.log("--->1024")
      FIG1Y = 600;
      FIG3Y = 700;
    } else if (document.documentElement.clientWidth > 1279) {
      // console.log("--->1279")
      FIG1Y = 700;
      FIG3Y = 800;
    }
    // --- test
    // const box = document.querySelector("h1")
    // debugger
    // gsap.set("h1", { height: 500 })

    // ScrollTrigger.refresh(true)
    // console.log("---story useeffect")
    // --- test

    var action = gsap
      .timeline({
        defaults: { duration: 1, ease: "none" },
        scrollTrigger: {
          trigger: "#storyHouse",
          scrub: true,
          // pin: true,
          // markers: true,
          start: "top center",
          // end: "+=1400",
          endTrigger: ".container-figure1",
          end: "top top",
        },
      })
      .to(
        FIG3,
        {
          y: FIG3Y,
        },
        0
      );

    var action1 = gsap
      .timeline({
        defaults: { duration: 1, ease: "none" },
        scrollTrigger: {
          trigger: "#storyFamily",
          scrub: true,
          // pin: true,
          // markers: true,
          start: "top center",
          // end: "+=1400",
          endTrigger: ".story-image_fence",
          end: "top top",
        },
      })
      .to(
        FIG1,
        {
          y: FIG1Y,
        },
        0
      );

    var action2 = gsap
      .timeline({
        defaults: { duration: 1, ease: "none" },
        scrollTrigger: {
          trigger: "#cfig",
          scrub: true,
          // pin: true,
          // markers: true,
          start: "top bottom",
          end: "top top",
          // endTrigger: ".container-figures",
          // end: "bottom center",
        },
      })
      .to(
        FIG11,
        {
          y: 1,
        },
        0
      )
      .to(
        FIG33,
        {
          y: 1,
        },
        0
      );

    var action3 = gsap
      .timeline({
        defaults: { ease: "none" },
        scrollTrigger: {
          trigger: ".t1",
          scrub: 1,
          // pin: true,
          // markers: true,
          start: "bottom bottom",
          // end: "+=1400",
          // endTrigger: ".story-image_az",
          end: "+=1000",
          // end: "bottom center",
        },
      })
      .to("#rect", {
        // duration: 11,
        // repeat: 12,
        // repeatDelay: 3,
        yoyo: true,
        ease: "none",
        motionPath: {
          path: "#path",
          align: "#path",
          // autoRotate: true,
          alignOrigin: [0.5, 0.5],
        },
      });

    // gsap.to("#rect", {
    //   duration: 5,
    //   repeat: 12,
    //   repeatDelay: 3,
    //   yoyo: true,
    //   ease: "power1.inOut",
    //   motionPath: {
    //     path: "#path",
    //     align: "#path",
    //     autoRotate: true,
    //     alignOrigin: [0.5, 0.5],
    //   },
    // })
  });

  return (
    <>
      <Layout>
        <Helmet>
          <title>{t("seoTitle")}</title>
        </Helmet>
        {/* <Header /> */}
        <main>
          <div className="page page-garden px-4a md:px-16">
            <div className="page-title w-2/3 md:w-auto">
              <div className="page-title__name">{t("title")}</div>
            </div>

            <div className="page-header">
              <h1>
                <Trans>{t("header")}</Trans>
              </h1>
            </div>

            <div className="row box box_first">
              <div className="col box__image box__image_road md:w-2/3 order-1 md:order-2">
                <img
                  className="box__pic story-image__pic"
                  src={StoryRoad}
                  alt="Story Road"
                />
              </div>

              <div className="col box__caption w-2/3 md:w-1/3 order-2 md:order-3 self-end">
                {t("imageHouseViewCaption")}
              </div>

              <div className="col box__text box__text_road order-3 md:order-1">
                <div className="row">
                  <div className="col md:w-1/2">
                    <div className="box__indent">
                      {t("text1Part1")}
                      <a
                        href="https://ypmuseum.ru"
                        className=""
                        target="_blank"
                        rel="noreferrer"
                        className="link-custom link-line"
                      >
                        {t("linkMuseum")}
                      </a>

                      {t("text1Part2")}
                    </div>
                  </div>
                  <div className="col md:w-1/2">
                    <div className="box__indent">{t("text2")}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="md:flex md:flex-wrap md:-mx-4 story-apple-wrapper">
              <div className="story-image story-image_apples md:w-1/2 md:order-2 md:px-4">
                <img
                  className="story-image__pic story-image__pic_sm mx-auto md:mr-0 md:ml-auto"
                  src={StoryApples}
                  alt="Story Apples"
                />
              </div>

              <div className="hidden md:flex md:w-1/2 md:order-3 md:px-4"></div>
              <div className="story__caption story__caption_apple text-nano md:text-xs md:tracking-wider w-2/3 md:w-1/2 md:order-4 md:px-4">
                {t("imageGardenCaption")}
              </div>
              <div className="story__text story__text_pt story__text_apple w-full md:w-1/2 md:pr-3 md:pt-20 md:text-2xl leading-sm md:leading-11 md:order-1 md:px-4">
                <div className="story__text_indent">{t("text3")}</div>
              </div>
            </div>

            <h1 className="story-h1_pad w-full">
              {t("subHeader1Part1")}
              <br className="inline md:hidden" />
              {t("subHeader1Part2")}
            </h1>

            {/*
             */}
            <div className="md:block md:flex-wrap md:-mx-4 story-house-wrapper">
              <div className="story-image story-image_house md:-mx-4 md:px-4 container-figure3">
                <img
                  id="storyHouse"
                  className="story-image__pic story-image__pic_house md:w-2/3 md:px-4 md:ml-0 md:mr-auto z-1"
                  src={StoryHouse}
                  alt="Story House"
                />
                <img
                  id="FIG3"
                  className="story__figure3 w-1/3 md:px-4"
                  src={Figure3}
                  alt=""
                />
              </div>
            </div>

            {/*  */}

            <div className="story__caption text-nano md:text-xs md:tracking-wider w-2/3 md:w-1/2 pt-2 md:pt-4">
              <Trans>{t("imageHouseCaption")}</Trans>
            </div>
            <div className="story__text story__text_pt story__text_house w-2/3 md:w-1/2 md:pr-3 md:pt-20 md:text-2xl leading-sm md:leading-11">
              <div className="story__text_indent">{t("text4")}</div>
            </div>

            <div className="md:block md:flex-wrap md:-mx-4 story-family-wrapper">
              <div className="story-image story-image_family md:-mx-4 md:px-4 container-figure1">
                <img
                  id="storyFamily"
                  className="story-image__pic story-image__pic_family md:px-4 md:w-2/3 md:ml-auto z-1"
                  src={StoryFamily}
                  alt="Story Family"
                />
                <img
                  id="FIG1"
                  className="story__figure1 w-1/3 md:px-4"
                  src={Figure1}
                  alt=""
                />
              </div>
              {/* <div className="w-1/3">.</div> */}
            </div>
            <div className="flex md:-mx-4">
              <div className="block w-1/3 md:px-4"></div>
              <div className="story__caption text-nano md:text-xs md:tracking-wider w-2/3 md:w-1/2 pt-2 md:pt-4 md:px-4 z-1">
                <Trans>{t("imageFamilyCaption")}</Trans>
              </div>
              <div className="hidden md:block md:w-1/6 md:px-4 z-1"></div>
            </div>

            {/*  */}

            <div className="flex md:-mx-4">
              <div className="block w-1/3 md:px-4"></div>
              <div className="story__text story__text_pt story__text_family w-2/3 md:w-1/2 md:pr-3 md:pt-20 md:text-2xl leading-sm md:leading-11 md:px-4 z-1">
                <div className="story__text_indent">{t("text5")}</div>
              </div>
              <div className="block md:w-1/6 z-1"></div>
            </div>

            <div className="flex flex-wrap md:-mx-4">
              <div className="story-image story-image_fence z-1 md:w-2/3 md:px-4">
                <img
                  src={StoryFence}
                  className="story-image__pic"
                  alt="Story Fence"
                />
              </div>

              <div className="story__caption text-nano md:text-xs md:tracking-wider w-2/3 md:w-1/3 md:pt-4 md:self-end md:px-4 md:pb-1">
                {t("imageFenceCaption")}
              </div>
            </div>

            <div className="flex md:-mx-4">
              <div className="hidden md:block md:w-1/3 md:px-4 z-1"></div>
              <div className="story__text story__text_pt story__text_fence w-auto md:w-1/2 md:pr-3 md:pt-20 md:text-2xl leading-sm md:leading-11 md:px-4">
                <div className="story__text_indent">{t("text6")}</div>
              </div>
            </div>

            <div id="cfig" className="container-figures flex -mx-4a">
              <div className="figure-wrapper md:px-4">
                <img
                  id="FIG11"
                  className="story__figure11"
                  src={Figure11}
                  alt="figures"
                />
              </div>
              <div className="figure-wrapper md:px-4">
                <img
                  id="FIG22"
                  className="story__figure22"
                  src={Figure22}
                  alt="figures"
                />
              </div>
              <div className="figure-wrapper md:px-4">
                <img
                  id="FIG33"
                  className="story__figure33"
                  src={Figure33}
                  alt="figures"
                />
              </div>
            </div>
          </div>

          <div className="page-footer-wrapper">
            <div className="page-footer px-4a md:px-16">
              <h1 className="story-h1_yasnaya w-full">
                <Trans>{t("subHeader2")}</Trans>
              </h1>

              <div className="flex flex-wrap md:-mx-4">
                <div className="story-image story-image_typist md:w-2/3 md:px-4">
                  <img
                    src={StoryTypist}
                    className="story-image__pic"
                    alt="Story Typist"
                  />
                </div>

                <div className="story__caption text-nano md:text-xs md:tracking-wider w-2/3 md:w-1/3 md:pt-4 md:self-end md:px-4 md:pb-1">
                  {t("imageTypistCaption")}
                </div>
              </div>

              <div className="flex md:-mx-4">
                <div className="md:flex md:w-1/6 md:px-4"></div>
                <div className="story__text story__text_pt story__text_fence w-auto md:w-1/2 md:text-2xl leading-sm md:leading-11 md:px-4">
                  <div className="story__text_indent">{t("text7")}</div>
                </div>
              </div>
              <div className="flex flex-wrap md:-mx-4">
                <div className="md:w-1/2 md:px-4">
                  <div className="story-image story-image_typewriter">
                    <img
                      src={StoryTypewriter}
                      className="story-image__pic story-image__pic_sm"
                      alt="Story Typewriter"
                    />
                  </div>
                  <div className="story__caption text-nano md:text-xs md:tracking-wider w-2/3 md:w-2/3 pt-2 md:pt-4">
                    {t("imageTypewriterCaption")}
                  </div>
                </div>

                <div className="md:w-1/2 md:px-4">
                  <div className="story-image story-image_letter">
                    <img
                      src={StoryLetter}
                      className="story-image__pic story-image__pic_sm"
                      alt="Story Letter"
                    />
                  </div>
                  <div className="story__caption text-nano md:text-xs md:tracking-wider w-2/3 md:w-2/3 pt-2 md:pt-4">
                    {t("imageLetterCaption")}
                  </div>
                </div>
              </div>

              <div className="flex md:-mx-4">
                <div className="md:flex md:w-1/3 md:px-4"></div>
                <div className="story__text story__text_pt story__text_fence w-auto md:w-1/2 md:text-2xl leading-sm md:leading-11 md:px-4">
                  <div className="story__text_indent">{t("text8Part1")}</div>
                  <div className="story__text_indent">{t("text8Part2")}</div>
                </div>
              </div>

              <div className="flex flex-wrap md:-mx-4">
                <div className="story-image story-image_typist md:w-2/3 md:px-4">
                  <img
                    src={StoryType}
                    className="story-image__pic"
                    alt="Story Type"
                  />
                </div>

                <div className="story__caption text-nano md:text-xs md:tracking-wider w-2/3 md:w-1/3 md:pt-4 md:self-end md:px-4 md:pb-1">
                  {t("imageTypeCaption")}
                </div>
              </div>

              {/* <div className="story__caption text-nano md:text-xs md:tracking-wider w-2/3 md:w-1/4 pt-2 md:pt-4">
              илья толстой, директор «яснополянских садов» и конь багряный
            </div> */}
              <div className="flex md:-mx-4">
                <div className="md:flex md:w-1/3 md:px-4"></div>
                <div className="story__text story__text_pt story__text_fence w-auto md:w-1/2 md:text-2xl leading-sm md:leading-11 md:px-4">
                  <div className="story__text_indent">{t("text9")}</div>
                </div>
              </div>
              <div className="t1">
                <div className="story-image story-image_az">
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 1312 1213"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M438.136 1213C633.13 1213 787.2 1121.54 881.086 962.698V981.952C881.086 1114.32 970.158 1213 1095.34 1213C1220.52 1213 1312 1114.32 1312 981.952V931.411C1312 902.53 1287.93 878.462 1259.04 878.462C1230.15 878.462 1206.08 902.53 1206.08 931.411V981.952C1206.08 1056.56 1160.34 1107.1 1095.34 1107.1C1030.34 1107.1 987.009 1056.56 987.009 981.952V310.47C987.009 110.71 832.939 0 565.725 0C255.178 0 77.0349 101.083 77.0349 291.216C77.0349 361.012 134.811 416.367 204.624 416.367C269.622 416.367 327.398 361.012 327.398 291.216C327.398 235.861 284.066 192.54 226.29 192.54H223.883C281.659 137.185 394.804 105.897 565.725 105.897C777.571 105.897 881.086 173.286 881.086 310.47C881.086 425.994 772.756 486.163 491.097 510.23C163.699 539.111 0 649.821 0 856.802C0 1066.19 182.958 1213 438.136 1213ZM438.136 1107.1C240.734 1107.1 105.923 1003.61 105.923 856.802C105.923 714.803 226.29 640.195 500.727 616.127C676.462 601.687 801.644 567.992 881.086 515.044V529.484C881.086 885.682 710.165 1107.1 438.136 1107.1Z"
                      fill="#828282"
                    />
                    <ellipse
                      id="rect"
                      cx="1259"
                      cy="931.5"
                      rx="53"
                      ry="52.5"
                      fill="white"
                    />
                    <ellipse
                      cx="228"
                      cy="289.5"
                      rx="99"
                      ry="97.5"
                      fill="white"
                    />
                    <path
                      id="path"
                      d="M1259 931.353V981.918C1259 1075.76 1198.83 1160 1095.31 1160C991.796 1160 934.01 1075.76 934.01 981.918V310.482C934.01 209.412 885.893 53 565.738 53C238.359 53 130.01 163.683 130.01 291.2M934.068 529.459C934.068 895.236 755.932 1159.88 438.165 1159.88C223.913 1159.88 53 1044.37 53 856.672C53 668.977 219.078 587.189 495.951 563.13C830.553 534.236 934.068 447.612 934.068 310.482V529.459Z"
                      stroke="white"
                      strokeWidth="1.6"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeDasharray="0.1 6"
                    />
                  </svg>
                  {/* <img src={StoryAz} alt="Story Az" /> */}
                </div>
              </div>
              {/*  */}
              {/*  */}
            </div>
          </div>
        </main>
        <Footer footerInvert="invert" />
      </Layout>
    </>
  );
};

export default Story;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["story", "common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
